<template>
  <div class="form-content-container">
    <Form class="form-container" ref="formValidate">
      <div class="form-item">
        <div class="title">
          <Input
            placeholder="请输入标题(5-30字)"
            v-model.trim="formValidate.curNum"
            maxlength="30"
          />
        </div>
        <div class="number">{{ formValidate.curNum.length }}/30</div>
      </div>
      <div class="form-item">
        <div>
          <div id="toolbar-container" class="toolbar"></div>
          <div id="text-container" class="text"></div>
        </div>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入关键词，多个关键词之间用英文逗号“,”隔开"
          v-model.trim="formValidate.keyword"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.abstract"
          maxlength="200"
          placeholder="请输入摘要（摘要会在列表等区域外露，帮助用户快速了解内容，如不填写则默认抓取正文前200字用做展示）"
        />
        <div class="number">{{ formValidate.abstract.length }}/200</div>
      </div>
      <div class="form-item">
        <label for="" style="height: 70px;">发布栏目：</label>
        <div class="checkbox" style="flex-direction: column">
          <CheckboxGroup v-model="columnNum">
            <Checkbox disabled :label="columnNum[0]"></Checkbox>
            <Checkbox disabled :label="columnNum[1]"></Checkbox>
          </CheckboxGroup>
          <Tree
            :data="treeList"
            show-checkbox
            @on-check-change="changeCheck"
          ></Tree>
        </div>
      </div>
      <div class="form-item">
        <div class="checkbox" style="align-items: center">
          <label for="">封面：</label>
          <RadioGroup v-model.trim="picture" @on-change="handleChange">
            <Radio label="无图"></Radio>
            <Radio label="单图"></Radio>
          </RadioGroup>
          <PictureUpload
            @handleUpload="handleUpload"
            v-show="isSingle"
            :cover="formValidate.cover"
          ></PictureUpload>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <div class="checkbox">
          <label for="">分类：</label>
          <Select
            style="width: 200px"
            placeholder="请选择分类"
            v-model="formValidate.classification"
          >
            <Option :value="v.id" v-for="v in oneList" :key="v.id">{{
              v.name
            }}</Option>
          </Select>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <div class="checkbox" style="height: 38px">
          <label for="">是否原创：</label>
          <RadioGroup
            v-model.trim="formValidate.isOriginal"
            @on-change="handleChange1"
          >
            <Radio label="否" :value="1"></Radio>
            <Radio label="是" :value="0"></Radio>
          </RadioGroup>
          <Input
            v-if="isOrigin"
            placeholder="请输入出处"
            v-model.trim="formValidate.source"
          />
        </div>
        <span class="xing">*</span>
      </div>
    </Form>
    <div class="submit-container">
      <div class="btns">
        <Button
          :disabled="disableFlag"
          @click="handlePreview(5, '/informationpreview')"
          >预览</Button
        >
        <Button @click="handleSave(formValidate)" v-throttleClick="2000"
          >保存</Button
        >
        <Button @click="handleSubmit(formValidate)" v-throttleClick="2000"
          >发布</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import PictureUpload from "../components/PictureUpload.vue";
import { mapState } from "vuex";
import axios from "axios";
import preview from "@/mixins/preview.js";
export default {
  components: {
    PictureUpload,
  },
  mixins: [preview],
  created() {
    // 认证
    if (this.userInfo.user.customerType === 1) {
      this.isFlag = true;
    }
    this.getListByType({
      type: 1,
    });
    this.projectAndColumnListByType({
      type: 1,
    });
    if (this.$route.query.id) {
      this.getEcho(this.formValidate);
      this.disableFlag = false;
    }
  },
  mounted() {
    this.editor();
  },
  data() {
    return {
      isFlag: false,
      tempArr: [],
      picture: "",
      treeList: [], //发布栏目
      isSingle: false, // 是否单图
      isOrigin: true, // 是否原创
      formValidate: {
        content: "",
        curNum: "",
        keyword: "",
        abstract: "",
        interest: [],
        isOriginal: "",
        source: "",
        classification: "",
        cover: "",
      },
      oneList: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    columnNum() {
      let arr = [];
      if (sessionStorage.getItem("isSon") === "false") {
        if (this.userInfo.user.customerType === 4) {
          arr = ["百科号"];
        } else if (this.userInfo.user.customerType === 5) {
          arr = ["机构号"];
        } else if (
          this.userInfo.user.customerType === 1 ||
          this.userInfo.user.customerType === 2
        ) {
          arr = ["百家号"];
        }
      } else {
        arr = ["百家号", "学者空间"];
      }
      return arr;
    },
  },
  methods: {
    editor() {
      const E = window.wangEditor
      this.editor = new E("#toolbar-container", "#text-container"); // 传入两个元素
      let that = this;
      // 上传图片
      this.editor.config.customUploadImg = async function (
        resultFiles,
        insertImgFn
      ) {
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // resultFiles 是 input 中选中的文件列表
        let param = new FormData();
        param.append("file", resultFiles[0]);
        const resp = await axios.post(
          that.$global.url1 + "/zw-public/web/common/upload",
          param,
          config
        );
        // 上传图片，返回结果，将图片插入到编辑器中
        insertImgFn(that.$global.imgUrl + resp.data.content.url);
      };
      // 上传视频
      this.editor.config.customUploadVideo = async function (
        resultFiles,
        insertVideoFn
      ) {
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // resultFiles 是 input 中选中的文件列表
        let param = new FormData();
        param.append("file", resultFiles[0]);
        const resp = await axios.post(
          that.$global.url1 + "/zw-public/web/common/upload",
          param,
          config
        );
        insertVideoFn(that.$global.imgUrl + resp.data.content.url);
      };
      this.editor.config.onchange = function (html) {
        // 第二步，监控变化，同步更新到 textarea
        that.formValidate.content = html;
      };
      this.editor.create();
      // // 第一步，初始化 textarea 的值
      this.formValidate.content = this.editor.txt.html();
    },
    // 分类
    async getListByType(data) {
      const resp = await this.$apis.formServe.listByType(data);
      this.oneList = resp.data.filter(item => item.status == 1);
    },
    getTrees() {
      this.treeList = this.treeList.map((item) => {
        return {
          title: item.projectName + ' ——',
          expand: true,
          menuIds: item.projectId,
          checked: false,
          children:
            item.columns &&
            item.columns.map((v) => {
              return {
                title: v.columnName,
                menuIds: item.projectId,
                id: v.id,
                checked: false,
              };
            }),
        };
      });
    },
    // 回显
    async getEcho(f) {
      const resp = await this.$apis.formServe.getEcho({
        articleId: this.$route.query.id,
        articleType: 5,
      });
      // this.projectAndColumnListByType();
      // 发布栏目
      resp.data.articlePushList.forEach((itemL) => {
        this.treeList.forEach((itemT) => {
          if (
            itemT.children &&
            itemT.children.length > 0 &&
            itemL.projectId !== undefined
          ) {
            itemT.children.forEach((itemC) => {
              if (itemC.id == itemL.channelId1) {
                itemC["checked"] = true;
              }
            });
          } else {
            if (itemT.menuIds == itemL.projectId) {
              itemT["checked"] = true;
            }
          }
        });
      });
      const r = resp.data.data;
      f.curNum = r.title;
      f.keyword = r.keyword;
      f.abstract = r.artSummary;
      this.editor.txt.html(r.content); // 重新设置编辑器内容
      f.content = r.content;
      f.cover = r.cover;
      this.picture = r.cover ? "单图" : "无图";
      this.isSingle = r.cover ? true : false;
      f.isOriginal = r.originalType === 1 ? "否" : "是";
      f.source = r.source;
      f.classification = +r.classification; // 分类
      if (f.isOriginal === "是") {
        f.source = "";
        this.isOrigin = false;
      }
    },
    handleUpload(url) {
      this.formValidate.cover = url; // 图片路径
    },
    changeCheck(item) {
      this.tempArr = item;
    },
    handleChange(e) {
      if (e === "单图") {
        this.isSingle = true;
      } else {
        // this.$delete(this.formValidate, "cover"); //如果是无图，就删除
        this.formValidate.cover = ""; //如果是无图，就删除
        this.isSingle = false;
      }
    },
    handleChange1(e) {
      if (e === "否") {
        this.isOrigin = true;
      } else {
        this.isOrigin = false;
      }
    },
    async projectAndColumnListByType(data) {
      const resp = await this.$apis.formServe.projectAndColumnListByType(data);
      this.treeList.push(resp.data[0]);
      this.getTrees();
    },
    // 保存
    async handleSave(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      if (this.tempArr.length === 0) {
        this.treeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((v) => {
              if (v.checked) {
                this.tempArr.push(v);
              }
            });
          } else {
            if (item.checked) {
              this.tempArr.push(item);
            } else {
              this.tempArr = [];
            }
          }
        });
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return false;
      }
      if (this.isOrigin && formValidate.source === "") {
        this.$Message.error("出处不能为空");
        return false;
      }
      if (this.isOrigin && formValidate.source.length > 20) {
        this.$Message.error("出处已达上限");
        return false;
      }
      if (this.picture === "单图" && !formValidate.cover) {
        this.$Message.error("图片不能为空");
        return false;
      }
      if (formValidate.curNum.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return false;
      }
      // 分隔
      if (/^,|[，。？！.?!]+|(,,)+|,$/.test(formValidate.keyword)) {
        this.$Message.error("多个关键词，只能以英文逗号分隔");
        return false;
      } else {
        let arr = formValidate.keyword.split(",");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == "") {
            this.$Message.error("关键词存在多余英文逗号");
            return false;
          }
        }
      }
      this.tempArr.forEach((e) => {
        if (e.children && e.children.length > 0) {
          return false;
        }
        this.formValidate.interest = [
          {
            projectId: e.menuIds || null,
            channelId1: e.id || null,
          },
        ];
      });
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 1,
        articleType: 5,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        articleId: this.$route.query.id || this.previewData.articleId,
        title: formValidate.curNum,
        content: formValidate.content,
        keyword: formValidate.keyword,
        artSummary: formValidate.abstract === "" ? null : formValidate.abstract,
        originalType: formValidate.isOriginal === "否" ? 1 : 0,
        articlePushList: this.tempArr.length > 0 ? formValidate.interest : [],
        source: formValidate.source,
        cover: formValidate.cover,
        classification: formValidate.classification,
      });
      // this.clearField(); // 清空
      if (resp.status === 200) {
        this.previewData = resp.data.data;
        this.$Message.success(resp.data.resultDesc);
      } else {
        this.$Message.error(resp.data.resultDesc);
      }
      this.disableFlag = false;
      return true;
    },
    async handleSubmit(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      if (this.tempArr.length === 0) {
        this.treeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((v) => {
              if (v.checked) {
                this.tempArr.push(v);
              }
            });
          } else {
            if (item.checked) {
              this.tempArr.push(item);
            } else {
              this.tempArr = [];
            }
          }
        });
      }
      // // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return;
      }
      if (this.isOrigin && formValidate.source === "") {
        this.$Message.error("不能为空");
        return;
      }
      if (this.isOrigin && formValidate.source.length > 20) {
        this.$Message.error("出处已达上限");
        return;
      }
      if (this.picture === "单图" && !formValidate.cover) {
        this.$Message.error("图片不能为空");
        return;
      }
      if (formValidate.curNum.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return;
      }
      // 分隔
      if (/^,|[，。？！.?!]+|(,,)+|,$/.test(formValidate.keyword)) {
        this.$Message.error("多个关键词，只能以英文逗号分隔");
        return;
      } else {
        let arr = formValidate.keyword.split(",");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == "") {
            this.$Message.error("关键词中存在多余英文逗号");
            return;
          }
        }
      }
      formValidate.interest = [];
      this.tempArr.forEach((e) => {
        if (e.children && e.children.length > 0) {
          return false;
        }
        formValidate.interest.push({
          projectId: e.menuIds || null,
          channelId1: e.id || null,
        });
      });
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 0,
        articleType: 5,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        title: formValidate.curNum,
        content: formValidate.content,
        keyword: formValidate.keyword,
        artSummary: formValidate.abstract,
        originalType: formValidate.isOriginal === "否" ? 1 : 0,
        articlePushList: this.tempArr.length > 0 ? formValidate.interest : [],
        source: formValidate.source,
        cover: formValidate.cover,
        articleId: this.$route.query.id || this.previewData.articleId,
        classification: formValidate.classification,
      });
      this.clearField(); // 清空
      if (resp.status === 200) {
        this.$Message.success(resp.data.resultDesc);
      }
      this.$router.push("/user/message"); //
      console.log(resp);
    },
    clearField() {
      this.formValidate.content = "";
      this.formValidate.curNum = "";
      this.formValidate.keyword = "";
      this.formValidate.abstract = "";
      this.formValidate.source = "";
      this.formValidate.isOriginal = "";
      this.picture = "";
      this.tempArr = [];
      this.formValidate.classification = "";
    },
    isEmpty(f) {
      if (
        f.keyword === "" &&
        f.content === "" &&
        f.curNum === "" &&
        this.picture === "" &&
        f.isOriginal === "" &&
        f.classification === ""
      ) {
        this.$Message.error("不能为空");
        return true;
      } else if (f.keyword === "") {
        this.$Message.error("关键词不能为空");
        return true;
      } else if (f.content === "") {
        this.$Message.error("正文不能为空");
        return true;
      } else if (f.curNum === "") {
        this.$Message.error("标题不能为空");
        return true;
      } else if (this.picture === "") {
        this.$Message.error("封面不能为空");
        return true;
      } else if (f.isOriginal === "") {
        this.$Message.error("原创不能为空");
        return true;
      } else if (f.classification === "") {
        this.$Message.error("分类不能为空");
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-tree .ivu-checkbox-wrapper {
  margin: 0;
}
::v-deep .ivu-tree  li ul {
  padding:0;
}
#text-container ::v-deep table {
  th,
  td {
    width: 200px;
    height: 35px;
    vertical-align: top;
    word-break: break-all;
  }
}
.form-content-container {
  margin: 38px auto 0;
  position: relative;
}
#text-container ::v-deep .placeholder {
  color: #999;
}
#text-container ::v-deep .w-e-text-container {
  z-index: 1 !important;
}
#toolbar-container ::v-deep .w-e-toolbar {
  justify-content: center;
  z-index: 2 !important;
}
#text-container {
  width: 100%;
  min-height: 300px;
  border: none;
  outline: none;
  padding: 4px 7px;
  color: #515a6e;
}
.form-container {
  width: 1200px;
  margin: 4px auto 30px;
  background-color: #fff;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
}
::v-deep .ivu-tree-children li {
  display: flex;
  align-items: center;
}
::v-deep .ivu-tree-children li .ivu-tree-arrow {
  display: none;
}
// ::v-deep .ivu-tree-children li .ivu-tree-children li {
//   margin: 30px 40px 0 -60px;
// }
// ::v-deep .ivu-tree-children li .ivu-tree-title {
//   width: 100px;
// }
::v-deep .ivu-radio-group {
  white-space: nowrap;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
::v-deep .ivu-form-item {
  width: 100%;
  margin-bottom: 0;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.ivu-input-wrapper ::v-deep textarea {
  border: none;
}
.form-container {
  padding: 60px 100px 0;
  .form-item {
    min-height: 90px;
    padding: 25px 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    .title {
      width: 900px;
      height: 38px;
      line-height: 38px;
      position: relative;
      ::v-deep input {
        font-size: 36px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .xing {
      width: 30px;
      color: red;
      text-align: center;
    }
    .left,
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .plus {
        width: 30px;
        height: 30px;
        border: 1px solid #dcdcdc;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        font-size: 2em;
      }
    }
    .left {
      border-right: 1px solid #dcdcdc;
      width: 355px;
    }
    .right {
      margin-left: 10px;
      width: 642px;
    }
    .year,
    .center,
    .stage {
      display: flex;
      align-items: center;
    }
    .year {
      width: 299px;
      border-right: 1px solid #dcdcdc;
    }
    .center {
      width: 323px;
      border-right: 1px solid #dcdcdc;
    }
    .stage {
      width: 374px;
    }
    label {
      white-space: nowrap;
      margin-right: 20px;
    }
    .checkbox {
      width: 970px;
      display: flex;
      padding-left: 7px;
      label {
        margin-top: 7px;
      }
    }
    .upload {
      display: flex;
      padding-left: 7px;
      align-items: center;
    }
  }
}
.submit-container {
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 70px;
  .btns {
    margin-left: 800px;
  }
  button {
    width: 90px;
    height: 36px;
    margin: 0 10px;
    border: 1px dashed #333333;
    border-radius: 8px;
    &:last-child {
      background: #00a4ff;
      border: 1px solid #00a4ff;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
