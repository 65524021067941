<template>
  <div class="upload-container">
    <div
      class="demo-upload-list"
      v-for="(item, index) in uploadList"
      :key="index"
    >
      <img
        style="width: 58px; height: 58px; vertical-align: middle"
        v-lazy="$global.imgUrl + cover"
        alt=""
        v-if="cover && $route.query.id"
      />
      <img v-lazy="item.url" v-else />
      <div class="demo-upload-list-cover">
        <Icon
          type="ios-trash-outline"
          @click.native="handleRemove(index)"
        ></Icon>
      </div>
    </div>
    <Upload
      v-if="uploadList.length === 0"
      ref="upload"
      accept=".png, .jpg, .jpeg"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :on-success="handleSuccess"
      :format="['jpg', 'jpeg', 'png']"
      :max-size="2048"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :before-upload="handleBeforeUpload"
      :action="actionUrl"
      multiple
      :headers="{ token: token }"
      type="drag"
      style="display: inline-block; width: 58px"
    >
      <div style="width: 58px; height: 58px; line-height: 58px">
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    cover: {
      type: String,
    },
  },
  data() {
    return {
      defaultList: [
        {
          name: "a42bdcc1178e62b4694c830f028db5c0",
          url: "https://tse1-mm.cn.bing.net/th?id=OIP-C.UJn6UIedh0fIZk5V4f_5zgHaE8&w=145&h=100&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2",
        },
      ],
      imgName: "",
      uploadList: [],
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.cover) {
        this.uploadList = [this.cover];
      } else {
        this.uploadList = [];
      }
    }, 1000);
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    handleRemove(index) {
      this.uploadList.splice(index, 1);
      this.$emit("handleUpload", "");
    },
    handleSuccess(res, file) {
      this.$emit("handleUpload", res.content.url);
      const url = this.$global.imgUrl + res.content.url;
      this.uploadList.push({
        url,
      });
      file.url =
        "https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar";
      file.name = "7eb99afb9d5f317c912f08b5212fd69a";
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "The file format is incorrect",
        desc:
          "File format of " +
          file.name +
          " is incorrect, please select jpg or png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 2M.",
      });
    },
    handleBeforeUpload() {
      const check = this.uploadList.length < 1;
      if (!check) {
        this.$Notice.warning({
          title: "Up to five pictures can be uploaded.",
        });
      }
      return check;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ivu-upload {
  height: auto;
}
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
