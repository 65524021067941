export default {
    data() {
        return {
            disableFlag: true,
            previewData: {},
        }
    },
    // watch: {
    //     formData: {
    //         handler(newValue, oldValue) {
    //             // this.disableFlag = fa
    //             console.log(newValue,oldValue);
    //             console.log(JSON.stringify(newValue) == JSON.stringify(oldValue));
    //         },
    //         deep: true,
    //     },
    //     // treeList: {
    //     //   handler(newValue, oldValue) {
    //     //     this.disableFlag = fa
    //     //   },
    //     //   deep: true,
    //     // },
    // },
    // computed: {
    //     formData() {
    //         return JSON.parse(JSON.stringify(this.formValidate))
    //     }
    // },
    methods: {
        async handlePreview(num, path) {
            if (this.disableFlag) {
                return;
            }
            let flag = await this.handleSave(this.formValidate)
            if(!flag){
                return;
            }
            if (this.$route.query.id) {
                this.previewData = {
                    articleId: this.$route.query.id,
                    articleType: num,
                };
            }
            const resp = await this.$apis.formServe.setPreview(this.previewData);
            let url = this.$router.resolve({
                path,
                query: {
                    articleId: resp.data,
                },
            });
            window.open(url.href, "_blank");
        },
    }
}